@import 'assets/css/_mixins.scss';

.lc-header-black {
  
  // box-shadow: inset 0px 31px 72px -12px #FAC458;

  @media (min-width: 1024px) {
    // box-shadow: inset 0px 31px 72px -12px rgba(230, 230, 230, 0.8);
  }
}
.hidden-header {
  @include transform(translateY(-95px));
}
.no-hidden {
  transform: none;
}

/*para que el blur no se sobreponga en las luces */
#lc_header::before {
  content: '';
  position: absolute;
  top: -10px;
  left: 0;
  right: 0;
  bottom: 10px;
  background-image: inherit;  
  background-size: cover;
  background-position: center;
  backdrop-filter: blur(8px);
  // filter: blur(8px);  
  z-index: -1;  
  
}

#lc_header {
  position: fixed;
  width: 100%;
  z-index: 10;
  @include transition(all 0.5s);
  // backdrop-filter: blur(8px);
  
  

  .bottom{
    background-color: aqua;
    background: repeat url('../../assets/img/christmas/christmas-lights.svg');
    width: 100%;
    background-size: contain;
    height: 20px;
    // background-color: aqua;
    z-index: 1;
    

  }

  .lc-container-header {
    @extend %titleFont;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 10px 0px 0px;
    // backdrop-filter: blur(3px); /* esta joda hace que la galeria no sirva??????*/
    
    

    // LEFT
    .left {
      // backdrop-filter: blur(8px);
      position: relative;
      z-index: 20;

      .logo {
        width: 80px;
      }

      @media (min-width: 1024px) {
        .logo {
          width: 100px;
        }
      }
    }

    // CENTER
    .center {
      
      .show-menu {
        visibility: visible !important;
        opacity: 1 !important;
      }
      .container-items {
        opacity: 0;
        visibility: hidden;
        z-index: 10;
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: $main-color-1;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: opacity 0.3s, visibility 0.3s ease-in;

        .items {
          list-style: none;
          text-transform: uppercase;
          max-height: 80vh;
          overflow-y: auto;

          .item-active {
            opacity: 0.8;
          }
          li {
            font-size: 28px;
            text-align: center;
            padding: 5px;
            margin-bottom: 25px;
            a {
              color: #ffffff;
              text-decoration: none;
            }
            &:last-child {
              margin-bottom: 0px;
            }
          }
        }
      }

      @media (min-width: 1024px) {
        .container-items {
          position: relative;
          visibility: visible !important;
          opacity: 1 !important;
          background-color: transparent;

          .items {
            display: flex;
            align-items: center;
            text-transform: inherit;

            .item-active {
              opacity: 1;
              color: $main-color-1;
            }
            li {
              font-size: 21px;
              margin-bottom: 0px;
              margin-right: 10px;

              &:last-child {
                margin-right: 0px;
              }
              a {
                color: #960609;
                @include transition(color 0.3s);
                &:hover {
                  color: $main-color-1;
                }
              }
            }
          }
        }
        .btn-order-now {
          display: none;
        }
      }
    }

    // RIGHT
    .right {
      position: relative;
      z-index: 20;

      .btn-order-now {
        @extend %textFont-Regular;
        font-size: 14px;
        display: none;
        border: 1px solid transparent;
        background-color: $main-color-2;
        text-decoration: none;
        transition: background-color 0.3s, color 0.3s ease-in;
        min-height: 18px;

        &:hover {
          background-color: #ffffff;
          color: $main-color-2;
          border: 1px solid $main-color-2 !important;
        }
      }
      #icon_menu {
        font-size: 30px;
        color: #ffffff;
        cursor: pointer;
      }

      @media (min-width: 1024px) {
        .btn-order-now {
          display: block;
        }
        #icon_menu {
          display: none;
        }
      }
      @media (min-width: 1180px) {
        .btn-order-now {
          margin-right: 35px;
        }
      }
    }
  }
}
//
.global-whats-icon {
  position: fixed;
  bottom: 10px;
  left: 10px;
  width: 70px;
  height: 70px;
  cursor: pointer;
  z-index: 7;

  @media (min-width: 1024px) {
    width: 90px;
    height: 90px;
    left: 30px;
    bottom: 30px;
  }
}
