.main-platestwo::before{
    content: "";
    background:  url("../../assets/img/christmas/hotdealsbg.png");
    background-color: red !important;
    position: absolute;
    inset: 0;
    
    background-size: contain;
    z-index: 1;
    opacity: 5%;
    animation: movingBG 60s ease-in-out infinite;
    

}

@keyframes movingBG {
    0%{
        background-position: 0 0;
    }

    50%{
        background-position: 100% 0px;
    }

    100%{
        background-position: 0% 0px;
    }
}

.main-platestwo {
    position: relative;
    background-color: #FFEECC;
    padding: 3em 1em;
    display: flex;
    justify-content: center;
    align-items: center;

    

    

    .platestwo {
        padding: 1em;
        border: 6px dashed #D63130;
        width: fit-content;
        z-index: 2;
        backdrop-filter: blur(4px);
        



        .top {
            display: flex;
            justify-content: center;
            padding: 1em 0;

            
            .ribbon {
                font-size: 28px;
                font-weight: bold;
                color: #fff;
                font-size: 1.7em;

                @media (max-width: 485px) {
                    font-size: 1.2em;
                    padding: 0 30px;

                }

            }

            .ribbon {
                --r: .8em;
                /* control the ribbon shape */

                padding-inline: calc(var(--r) + .3em);
                line-height: 1.8;
                clip-path: polygon(0 0, 100% 0, calc(100% - var(--r)) 50%, 100% 100%, 0 100%, var(--r) 50%);
                background: #B42626;
                /* the main color */
                width: fit-content;
                padding: 0 100px;
                @media (max-width: 485px) {
                    font-size: 1.2em;
                    padding: 0 40px;

                }

            }
        }



        .plates-grid {
            display: grid;
            justify-content: center;
            align-items: center;
            
            grid-template-columns: repeat(3, 1fr);
            
            @media (max-width: 1272px) {
                grid-template-columns: repeat(2, 1fr); 

            }
            @media (max-width: 872px) {
                grid-template-columns: repeat(1, 1fr); 
                justify-items: center;

            }

            .gift-box, .git-top, .order-now{
                transition: all 0.2s ease-out;
            }

            /* gift hover animation*/
            .plate-container{
                .gift-hover-an{
                    

                    .gift-top{
                        background: white;
                        background-image: linear-gradient(to right, #ffffff 0%, #ffffff 44%, #dc2938 44%, #dc2938 56%, #ffffff 56%, #ffffff 100%);
                        border: 1px dashed #dc2938;
                        margin-left: 2px;
                        transition: background-image 0.1s ease-in;
                        height: 20px;
                    }
                    .gift-top::before, .gift-top::after{
                        border: 10px solid #dc2938;
                    }
                    
                    .gift-box{
                        background-color: white;
                        border: 1px dashed red;
                        transform: scale(1.0992);
                        // transition: all 0.1s ease-in;

                        .plate-title{
                            color: #b41623;
                        }
                        .description{
                            color: #dc2938;
                        }
                        
                        
                        .order-now{
                            background-color: #dc2938;
                            bottom: 10%;

                            .main-popup{
                                .ver-menu{

                                }
                                .pedir-ahora{

                                }
                            }
                            
                        }
                    }
                }
            }

            .plate-container{
                .gift:hover{
                    

                    .gift-top{
                        background: white;
                        background-image: linear-gradient(to right, #ffffff 0%, #ffffff 44%, #dc2938 44%, #dc2938 56%, #ffffff 56%, #ffffff 100%);
                        border: 1px dashed #dc2938;
                        margin-left: 2px;
                        transition: background-image 0.1s ease-in;
                        height: 20px;
                    }
                    .gift-top::before, .gift-top::after{
                        border: 10px solid #dc2938;
                    }
                    
                    .gift-box{
                        background-color: white;
                        border: 1px dashed red;
                        transform: scale(1.0992);
                        // transition: all 0.1s ease-in;

                        .plate-title{
                            color: #b41623;
                        }
                        .description{
                            color: #dc2938;
                        }
                        
                        
                        .order-now{
                            background-color: #dc2938;
                            bottom: 10%;

                            .main-popup{
                                .ver-menu{

                                }
                                .pedir-ahora{

                                }
                            }
                            
                        }
                    }
                }
            }

            .christmas-ball-container{
                // transition: all 0.1s linear;
            }

            .plate-container{

                .ball-hover-an{
                    transform: scale(1.1);
                    margin-bottom: 3em;
                    // transition: all 0.1s ease-in;
                    
                    
                    .jjj{
                        // transition: all 0.1s ease-in;
                        display: none;
   
                    }
    
                    
                    .kkk{
                        // transition: all 0.1s ease-in;
                        border: 0px solid green;
                    }
                }

            }

            .plate-container{

                .christmas-ball-container:hover{
                    transform: scale(1.1);
                    margin-bottom: 3em;
                    // transition: all 0.1s ease-in;
                    
                    

                }

            }
              

            .plate-container {
                display: flex;
                width: 350px;
                height: 350px;
                position: relative;


                @media (max-width: 485px) {
                    width: 320px;
                    height: 350px;
                    

                }
                @media (max-width: 323px) {
                    width: 280px;
                    height: 400px;
                    

                }




                .christmas-ball-container {
                    position: absolute;
                    
                    align-self: flex-end;
 


                    .christmas-ball {
                        position: relative;
                        width: 150px;
                        height: 150px;
                        border-radius: 50%;
                        background: radial-gradient(circle, #ad1421 60%, #971010 100%);
                        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3), inset 0 -3px 6px rgba(0, 0, 0, 0.2);
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        overflow: hidden;
                        
                        border: 2px solid #fff;
                        
                        padding: 5px;


                        @media (max-width: 323px) {
                            width: 120px;
                            height: 120px;
                            
        
                        }
                    }



                    .christmas-ball img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        
                        border-radius: 50%;
                        
                    }

                    /* Top Holder */
                    .top-holder {
                        position: absolute;
                        top: -20px;
                        left: 50%;
                        transform: translateX(-50%);
                        width: 40px;
                        height: 20px;
                        background: #c0c0c0;
                        border-radius: 10px 10px 0 0;
                        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }

                    .top-holder::before {
                        content: '';
                        position: absolute;
                        top: -10px;
                        width: 15px;
                        height: 10px;
                        border: 2px solid #c0c0c0;
                        border-radius: 50%;
                        background: transparent;
                        
                    }

                    /* String */


                }


            }

            .gift {
                position: absolute;
                right: -27%;
                top: 50%;
                width: 250px;
                height: 250px;
                transform: translate3d(-50%, -50%, 0);

                @media (max-width: 485px) {
                    height: 250px;

                }
                @media (max-width: 323px) {
                    width: 210px;
                    height: 300px;
                    

                }
            }

            .gift-top {
                position: relative;
                width: 110%;
                height: 15%;
                background: #F54537;
                border-radius: 5px 5px 0 0;
                transform: translate3d(-5%, 0, 0);
                background-image: linear-gradient(to right, #F54537 0%, #F54537 44%, #FFC113 44%, #FFC113 56%, #F54537 56%, #F54537 100%);
            }

            .gift-top::before,
            .gift-top::after {
                content: "";
                width: 15px;
                height: 15px;
                border: 10px solid #FFC113;
                border-radius: 50% 50% 0 50%;
                position: absolute;
                top: 0;
                left: 50%;
                transform: translate3d(-100%, -100%, 0) skew(10deg, 10deg);
            }

            .gift-top::after {
                border-radius: 50% 50% 50% 0;
                transform: translate3d(0, -100%, 0) skew(-10deg, -10deg);
            }



            .gift-box {
                width: 100%;
                height: 100%;
                background-color: #df3125;
                border-radius: 0 0 5px 5px;
                display: flex;
                flex-direction: column;


                span {
                    color: white;
                    font-size: 1.3em;
                    font-weight: 700;
                    text-align: right;
                    margin-left: 15%;
                    padding-right: 15px;
                    margin: 5px 0;
                    // background-color: green;
                    @media (max-width: 485px) {
                        font-size: 1em;
    
                    }
                }

                p {
                    color: #e9e9e9;
                    text-align: right;
                    margin-left: 40%;
                    padding-right: 15px;
                    // background-color: #FFEECC;
                }

                .order-now {
                    color: #ffffff;
                    text-align: right;
                    margin-left: 35%;


                    position: absolute;
                    bottom: -10px;
                    right: 0;
                    background-color: #fabb0f;
                    font-weight: 700;
                    padding: 5px 10px;

                    @media (max-width: 485px) {
                        font-size: 0.8em;
    
                    }

                    .main-popup {
                        position: absolute;
                        background-color: #B42626;
                        width: 120px;
                        padding: 9px 2px;
                        border: 2px solid #ffffffbb;
                        left: 30px;
                        bottom: -40px;
                        display: none;
                        z-index: 10;

                        transition: all 1s ease;
                        

                        border-radius: 7px;
                        div{
                            width: fit-content;
                            padding: 0 0 0 10px;
                            border-bottom: 1px solid #d8cdcd00;
                            cursor: pointer;
                            display: flex;
                            margin: 5px auto;
                            padding: 0;
                            
                        }

                        a {
                            text-decoration: none;
                            color: inherit;
                            padding: 0;
                            font-weight: 450;
                            text-align: left;
                            font-size: 1em;
                            width: fit-content;
                            margin: 0 auto;
                            
                        }:hover{
                            // color: #971010;
                            border-bottom: 1px solid #d8cdcd;
                            cursor: pointer;
                        }

                        .ver-menu{
                            display: block;
                        }
                        .pedir-ahora{
                            display: block;
                        }
                    }:hover{
                        display: contents;
                    }



                    
                }:hover{
                    cursor: default;
                    .main-popup{
                        display: block;
                    }
                }

            }
        }

        .bottom {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            padding: 3em 0 1.5em 0;
            gap: 2em;

            span {
                text-align: center;
                width: fit-content;
                font-size: 1.5em;
            }

            .ver-carta {
                padding: 0.7em 2em;

                a {
                    text-decoration: none;
                    color: inherit;
                    background-color: #D63130;
                    color: white;
                    font-weight: 600;
                    padding: 0.7em 2em;
                    font-size: 1.3em;
                    border: 1px solid #D63130;
                }

                a:hover {
                    background-color: #fafafa;
                    border: 1px solid #D63130;
                    transition: all 0.1s ease-in;
                    color: #D63130;
                }

            }

        }
    }
}













/*_______________________*/

