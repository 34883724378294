@import 'assets/css/_mixins.scss';

.menu-page {
   min-height: 800px;
   
   .menu-container {
       .container-download-menu {
          text-align: right;
          margin-bottom: 30px;
          display: flex;
          justify-content: flex-end;
         
          a {  
             text-decoration: none;
          }
          .btn-download-menu {
               @extend %btn-order-now;
               text-decoration: none;
               padding: 12px 20px 10px 20px;
               background-color: $main-color-2;
              display: flex;
              align-items: center;

               span {
                  margin-left: 10px;
                  margin-top: -2px;
               }
          }
       }
   }
}