@import 'assets/css/_mixins.scss';

.plate {
  margin: auto auto 25px auto;

  .container-image {
    border: 3px solid #ffffff;
    border-bottom: 0px;
    border-radius: 0px 20px 0px 0px;
    padding: 5px 0px 5px 0px;
    margin: auto;
    text-align: center;
    box-shadow: 0 3px 15px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    height: 160px;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;

    .image {
      width: 100%;
      width: 160px;
    }
  }

  .content {
    text-align: center;
    position: relative;
    background-color: #ffffff;
    border-radius: 0px 0px 20px 20px;
    padding: 15px 8px 15px 8px;
    box-shadow: 0 3px 15px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);

    .title {
      @extend %titleFont;
      font-size: 22px;
      color: $main-color-1;
      margin-bottom: 5px;
    }
    .description {
      margin-bottom: 15px;
    }

    .bottom {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;

      .btn-see-details {
        font-size: 16px;
        color: $main-color-2;
        font-weight: 600;
        background: none;
        padding: 0px;
        border: 0px;
        cursor: pointer;
        margin-bottom: 12px;
        transition: color 0.3s;
        border-bottom: 1px solid $main-color-2;
        padding: 9px 9px;

        &:hover {
          color: white;
          border-color: white;
        }
      }

      .price {
        font-size: 18px;
        color: $main-color-2;
        text-align: right;
      }
      .btn-order-desktop {
        display: none;
      }
      .btn-order {
        color: #ffffff;
        text-decoration: none;
        border: 1px solid $main-color-2;
        background-color: $main-color-2;
        padding: 5px 8px 5px 8px;
        border-radius: 0px 0px 0px 20px;

        @media (max-width: 1023px) {
          margin-bottom: 10px;
        }
      }
    }
  }

  @media (min-width: 1024px) {
    position: relative;
    display: flex;
    justify-content: flex-end;
    min-width: 314px;
    &:hover {
      z-index: 2;
    }

    &:hover {
      .content {
        background-color: $main-color-1;
        @include transform(translate(0px, -10px));

        .title,
        .description {
          // color: #ffffff;
        }
        .bottom {
          .price {
            opacity: 0;
            z-index: -1;
          }
          .btn-order-desktop {
            display: block;
          }
        }
      }
    }

    .container-image {
      border: 0px solid #ffffff;
      padding: 0px 0px 0px 0px;
      box-shadow: 0px 0px;
      position: absolute;
      left: 0;
      bottom: 0;
      margin-bottom: -5px;
      z-index: 1;
      width: 160px;
      border-radius: 50%;

      .image {
        width: 100%;
        width: 160px;
      }
    }

    .content {
      position: relative;
      background-color: #ffffff;
      border-radius: 0px 0px 25px 20px;
      padding: 15px 15px 15px 15px;
      width: 70%;
      transition: background-color 0.3s, transform 0.3s;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-end;
      min-height: 185px;

      .title {
        // letter-spacing: 1px;
        text-align: left;
        margin-bottom: 10px;
      }

      .description {
        font-size: 14px;
        width: 72%;
        float: right;
        text-align: right;
      }

      .bottom {
        clear: both;
        justify-content: flex-end;
        position: absolute;
        bottom: 15px;

        .btn-see-details {
          display: none;
        }

        .btn-order {
          display: none;
          position: absolute;
          font-size: 14px;
          right: 0;
          border-radius: 10px 0px 10px 0px;
          transition: border 0.3s;
          min-width: 100px;
          margin-right: -5px;

          &:hover {
            border: 1px solid white;
          }
        }

        .btn-order-desktop {
          display: block;
          min-width: 120px;
          &:hover {
            .item-wrapper {
              display: block;
            }
          }

          .item-wrapper {
            position: absolute;
            bottom: -63px;
            box-shadow: 0 3px 15px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
            right: -80%;
            padding: 10px 14px;
            border-radius: 0px 0px 0px 20px;
            // background-color: $main-color-1;
            display: none;
            border: 1px solid $main-color-2;

            p {
              font-size: 15px;
              color: $main-color-2;
              padding: 1px;
              margin-bottom: 6px;
              cursor: pointer;
              transition: border 0.5s;
              border-bottom: 1px solid transparent;

              a {
                text-decoration: none;
                color: $main-color-2;
              }
              // border: 1px solid;

              &:last-child {
                margin-bottom: 0;
              }

              &:hover {
                border-bottom: 1px solid;
              }
            }
          }
        }
      }
    }
  }

  @media (min-width: 760px) {
    width: 340px;
    margin: auto auto 45px auto;
  }

  @media (min-width: 1180px) {
    margin: auto auto 50px auto;
  }
}
