$background-size: 700px;
.main-greetings::before{
  content: '';
  position: absolute;
  inset: 0;
  background: url("../../assets/img/christmas/sliderbg.jpg");
  width: 100%;


  background-position: center;
  background-size: $background-size;
  backdrop-filter: blur(8px);
  filter: blur(8px);
  // animation: slide 35s linear infinite;
  z-index: 0;
  opacity: 30%;
  
}

// .main-greetings::after{
//   content: '';
//   position: absolute;
//   inset: 0;
//   background-color: rgba(0, 0, 0, 0.096);
//   width: 100%;


  
// }
.main-greetings{
  position: relative;
  width: 100%;
  height: 800px;
  background-color: #ffe2a7 !important; 
  @media (max-width: 595px){
    margin-top: 0em;
    
    
  }

  @media (max-width: 836px){
   height: 1150px;     
  }


  

  // .snow_wrap{
  //   height: 100%;
  //   width: 100%;
  //   background-size: cover;
  //   position: absolute;
  //   overflow: hidden;
  // }

  // .snow, .snow::after, .snow::before{
  //   content: "";
  //   position: absolute;
  //   inset: 0 0 0 0;
  //   top: -650px;
  //   background-image: 
  //   radial-gradient(4px 4px at 100px 50px, #fff, transparent),
  //   radial-gradient(6px 6px at 200px 150px, #fff, transparent),
  //   radial-gradient(3px 3px at 300px 250px, #fff, transparent),
  //   radial-gradient(4px 4px at 400px 350px, #fff, transparent),
  //   radial-gradient(6px 6px at 500px 100px, #fff, transparent),
  //   radial-gradient(3px 3px at 50px 200px, #fff, transparent),
  //   radial-gradient(4px 4px at 150px 300px, #fff, transparent),
  //   radial-gradient(6px 6px at 250px 400px, #fff, transparent),
  //   radial-gradient(3px 3px at 350px 500px, #fff, transparent);
  //   background-size: 650px 650px;


  //   animation: snowAnim 3s linear;
  //   animation-iteration-count: infinite;
  // }

  // .snow::after{
  //   margin-left: -250px;
  //   opacity: 0.5;
  //   filter:blur(2px);
  //   animation-direction: reverse;
  //   animation-duration: 6s;
  // }

  // .snow::before{
  //   margin-left: -350px;
  //   opacity: 0.7;
  //   filter: blur(1px);
  //   animation-direction: reverse;
  //   animation-duration: 6s;
  // }

  // @keyframes snowAnim {

  //   from{
  //     transform: translateY(0);
  //   }
  //   to{
  //    transform: translateY(650px); 
  //   }
    
  // }

  .greetings-content::before{
    
  }

  .greetings-content{
    animation: slide 60s linear infinite;
    position: absolute;
    width: 100%;
    // background-color: rgba(0, 119, 255, 0.548);
    padding: 10em 0em 5em 2em;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 500px;

    @media (max-width: 595px){
      margin-top: 0em;
      
      
    }
    
 

    
  
    @media (max-width: 836px){
        flex-direction: column-reverse;
        gap: 2em;
        padding: 9em 0em 5em 0em;
    }




    .leftside{
      display: flex;
      flex-direction: column;
      position: relative;


      @media (max-width: 836px){
        // background-color: aquamarine;
        width: 80%;
        align-self: center;
        justify-self: center;
        text-align: center;
        justify-content: center;
        align-items: center;

      }

      

      .furniture{
        width: 40px;
        height: 50px;
        position: absolute;
        left: -70px;
        top: -30px;
        transform: rotate(10deg);
        

      }
      
      // background-color: greenyellow;
      justify-self: flex-start;
      .dish-name{
        font-size: 2.5em;
        font-weight: 700;
        @media (max-width: 836px){
          font-size: 2em;
          
        }

        @media (max-width: 595px){

        font-size: 2em;
        
        }

      }

      width: 400px;
      .hot-title{
        font-size: 3em;
        font-weight: 700;
        color: #830000;
        @media (max-width: 836px){
          font-size: 2.5em;
          
        }
        @media (max-width: 595px){

          font-size: 2.5em;
          
        }

      }
      .description{

      }

      .order-button{
        margin: 1em 0;
        background-color: #830000;
        color: white;
        font-size: 1.2em;
        font-weight: 500;
        width: fit-content;
        position: relative;
        
        border: 1px solid #830000;
        cursor: pointer;
        @media (max-width: 836px){
          margin-top: 2em;
          
        }

        a{
          padding: 6px 1em;
          color: inherit;
          text-decoration: none;
        }

        img{
          height: 35px;
          width: 50px;
          position: absolute;
          bottom: -10px;
        }
      }

      .order-button:hover{
        background-color: #f5f5f5;
        color: #830000;
        border: 1px solid #830000;
        transition: all 0.2s ease-in;
      }

      .socialmedia-container{
        display: flex;
        gap: 10px;
        
        img{
          cursor: pointer;
          height: 30px;
          width: 30px;
        }
      }


    }

    .rightside{
      height: 100%;
      width: 600px;
      // background-color: rgba(255, 0, 0, 0.055);
      display: flex;
      justify-content: right;
      padding-right: 3em;
      position: relative;


      @media (max-width: 595px){
        justify-content: center;
        align-items: center;
        
        
      }
      @media (max-width: 836px){
        margin-top: 12em;
        padding: 0;
        
      }


      // background-color: aquamarine;


      /*svgs decos*/

      .ball1{
        transform: translate(-30%, -30%);
        height: 300px;
        position: absolute;
        left: -38em;
        top: 0em;
        // animation: pendulumSwing 10s ease-in-out infinite;

        @media (max-width: 836px){
          left: 0;
          top: -3em;
          
        }

        @media (max-width: 595px){
          height: 250px;
          left: 5em;
          top: -10em;
          
          
        }

        @media (max-width: 462px){
          height: 200px;
          left: 10em;
          top: -10em;
          
          
        }
        @media (max-width: 380px){
          left: 13em;
          
          
        }
        
      }

      .ball2{
        height: 150px;
        position: absolute;
        left: -31em;
        top: 0em;
        // animation: pendulumSwing 7s ease-in-out infinite;
        transform: translate(-30%, -30%) ;
      }

      .ball3{
        height: 400px;
        position: absolute;
        right: -13em;
        top: 0em;
        // animation: pendulumSwing 15s ease-in-out infinite;
        transform: translate(-30%, -30%); 

        @media (max-width: 836px){
          height: 200px;
          right: -2em;
          top: -5em;
          
        }
        @media (max-width: 595px){
          height: 200px;
          right: 4em;
          top: -10em;
          
          
        }
        
        @media (max-width: 462px){
          right: 10em;
          
          
        }
        
      }
      @keyframes pendulumSwing {
        0% {
          transform: translate(-30%, -30%) rotate(-3deg); 
        }
        50% {
          transform: translate(-30%, -30%) rotate(3deg); 
        }
        100% {
          transform: translate(-30%, -30%) rotate(-3deg); 
        }
      }

  
      
     
      

      .candy{
        width: 30px;
        height: 50px;
        position: absolute;
        bottom: 15%;
        left: 15%;

        @media (max-width: 836px){
          left: 10%;
          bottom: 10%;
          
        }
        @media (max-width: 462px){
          display: none;
          
          
        }
      }

      .bells{
        width: 50px;
        height: 35px;
        position: absolute;
        right: 10%;
        top: 0;

        @media (max-width: 836px){
          right: 20%;
          
        }
        @media (max-width: 595px){
          right: 30%;
          
          
        }
        @media (max-width: 462px){
          display: none;
          
          
        }
        
      }





      @media (max-width: 595px){
        justify-content: center;
        align-content: center;
        margin-top: 15em;
        
        
      }
      @media (max-width: 462px){
        gap: 0;
        padding: 0;
        
        
      }
    
      @media (max-width: 380px){
        flex-direction: column;
        
        
      }
      

      .christmas-ball-container{
        position: absolute;

        @media (max-width: 836px){
          position: relative;

        }



        
        .christmas-ball {
          position: relative;
          width: 450px; 
          height: 450px; 
          border-radius: 50%; 
          background: radial-gradient(circle, #ad1421 60%, #971010 100%);
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3), inset 0 -3px 6px rgba(0, 0, 0, 0.2);
          display: flex;
          justify-content: center;
          align-items: center;
          overflow: hidden; 
          border: 2px solid #fff; 
          padding: 5px;
          @media (max-width: 836px){
            width:  400px;
            height: 400px;
            
          }
          @media (max-width: 595px){
            width: 300px;
            height: 300px;
            
            
          }
          @media (max-width: 462px){
            width: 250px;
            height: 250px;
            
            
          }
          @media (max-width: 343px){
            width: 200px;
            height: 200px;
            
          }
        }
        
        .christmas-ball img {
          width: 100%;
          height: 100%;
          object-fit: cover; 
          border-radius: 50%; 
        }
        
        /* Top Holder */
        .top-holder {
          position: absolute;
          top: -20px; 
          left: 50%;
          transform: translateX(-50%);
          width: 40px;
          height: 20px;
          background: #540909; 
          border-radius: 10px 10px 0 0; 
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3); 
          display: flex;
          justify-content: center;
          align-items: center;
        }
        
        /* Loop inside the Holder */
        .top-holder::before {
          content: '';
          position: absolute;
          top: -10px; 
          width: 15px;
          height: 10px;
          border: 2px solid #540909; 
          border-radius: 50%; 
          background: transparent; 
        }
        
        /* String */
        .string {
          position: absolute;
          top: -100px; 
          left: 50%;
          transform: translateX(-50%);
          width: 2px;
          height: 140px; 
          background: #781010; 
          z-index: -1; 

          @media (max-width: 836px){
            top: -250px;
            height: 240px; 
            
          }
        }
        
      }

      .slider-btns{


        
        z-index: 1;
        @media (max-width: 836px){
          font-size: 0l9em;
        }

        @media (max-width: 490px){
          align-self: center;
          justify-self: center;
          // 
          position: absolute;
          
          bottom: 0;
          right: 20%;
          .previous, .next{
            line-height: 0;
            padding: 0px !important;
            width: 40px;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            
            
          }
        }
          
        @media (max-width: 380px){
          align-self: center;
          justify-self: center;
          // 
          position: absolute;
          right: 22%;
          bottom: 0;
          
          .previous, .next{
            line-height: 0;
            padding: 0px !important;
            width: 40px;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            
            
          }
        }

        @media (max-width: 343px){
          right: 28%;
          .previous, .next{
            line-height: 0;
            padding: 0px !important;
            width: 30px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            
            
          }
        }
        div{
          padding: 10px 15px;
            
          cursor: pointer;
        }
        display: flex;
        height: fit-content;
        align-self: flex-end;

        .previous{
          font-size: 40px;
        }
        .next{
          background-color: #830000;
          padding: 10px;
          border-radius: 5px;
          color: white;
          font-size: 40px;
        }
        .next:hover{
          background-color: #a31717;
          transition: all 0.1s ease-in;
        }

        .previous, .next{
          display: flex;
        }
      }
    }

    .slider-indicators {
      @media (max-width: 836px){
        display: none;
      }
      
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      align-self: flex-end;
      margin-bottom: 1em;

      .indicator {
        width: 13px;
        height: 6px;
        background-color: #ffffff;
        margin-right: 5px;
        opacity: 0.7;
        cursor: pointer;
        

        &:hover {
          width: 18px;
          opacity: 1;
        }
        &:last-child {
          margin-right: 0px;
        }
      }
      .indicator-active {
        width: 18px;
        background-color: #830000;
        opacity: 1;
      }
    }
  }

  .background-container {
    position: relative;
    height: 100vh;
    width: 100%;
  }
  
  .stars {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(white 1px, transparent 1px),
                radial-gradient(white 1px, transparent 1px);
    background-size: 2px 2px, 3px 3px;
    background-position: 0 0, 50px 50px;
    opacity: 0.8;
  }
  
  .landscape {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 40%;
    background: white;
    clip-path: polygon(0% 100%, 0% 30%, 10% 50%, 25% 40%, 50% 60%, 70% 50%, 90% 70%, 100% 30%, 100% 100%);
  }
  
  .scene {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 40%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: flex-end;
  }
  
  .tree {
    width: 60px;
    height: 80px;
    background: none;
    position: absolute;
  }
  
  .tree svg {
    width: 100%;
    height: 100%;
  }
  
  .gift {
    position: absolute;
    width: 50px;
    height: 50px;
    background: #f44336;
    border-radius: 10px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
  }
  
  .gift:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 10px;
    background: #ffeb3b;
    transform: translateY(-50%);
  }
  
  .gift:after {
    content: '';
    position: absolute;
    left: 50%;
    top: 0;
    width: 10px;
    height: 100%;
    background: #ffeb3b;
    transform: translateX(-50%);
  }
  
  
  .tree-1 {
    left: 10%;
    bottom: 20%;
  }
  
  .tree-2 {
    left: 50%;
    bottom: 10%;
  }
  
  .tree-3 {
    left: 30%;
    bottom: 20%;
  }
  
  .tree-4 {
    left: 75%;
    bottom: 10%;
  }
  
  .tree-5 {
    left: 60%;
    bottom: 15%;
  }

  .tree-6{
    left: 92%;
    bottom: 7%;
  }
  
  .gift-1 {
    left: 18%;
    bottom: 20%;
  }
  
  .gift-2 {
    left: 700%;
    bottom: 18%;
  }
  
  .gift-3 {
    left: 31%;
    bottom: 8%;
    
  }
  
  .gift-4 {
    left: 100%;
    bottom: 50%;
  }
  
  .gift-5 {
    left: 65%;
    bottom: 25%;
  }

  .background-container {
    position: absolute;
    height: 100%;
    width: 100%;
  }



  .landscape {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 20%;
    background: rgb(180, 38, 38);
    clip-path: polygon(0% 100%, 0% 30%, 10% 50%, 25% 40%, 50% 60%, 70% 50%, 90% 70%, 100% 30%, 100% 100%);
  }

  .scene {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 40%;
    display: flex;
    justify-content: space-around;
    align-items: flex-end;
  }

  .tree {
    width: 60px;
    height: 80px;
    background: none;
  }

  .tree svg {
    width: 100%;
    height: 100%;
  }

  .gift {
    position: relative;
    width: 50px;
    height: 50px;
    background: #d62828;
    border-radius: 4px;
  }

  .gift:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 10px;
    background: #fcbf49;
    transform: translateY(-50%);
  }

  .gift:after {
    content: '';
    position: absolute;
    left: 50%;
    top: 0;
    width: 10px;
    height: 100%;
    background: #fcbf49;
    transform: translateX(-50%);
  }

}



