@import '_variables.scss';

@mixin transition($property) {
	-webkit-transition: $property;
	-moz-transition: $property;
	-ms-transition: $property;
	-o-transition: $property;
	transition: $property;
}

@mixin transform($property) {
    -moz-transform: $property;
    -o-transform: $property;
    -ms-transform: $property;
    -webkit-transform: $property;
    transform: $property;
}

@mixin animation($property) {
    -webkit-animation: $property;
   -moz-animation: $property;
   -o-animation: $property;
   animation: $property;
}

%lc-default-title {
    position: relative;
    text-align: center;
    font-size: 23px;
    margin-bottom: 30px;

    .back {
        text-transform: uppercase;
        font-size: 30px;
        color: $main-color-2;
        position: absolute;
        width: 100%;
        left: 0;
        margin-top: -3px;
        opacity: 0.1;
    }
    .highlighted {
        color: $main-color-2;
    }

    @media (min-width: 1024px) {
        font-size: 26px;
        margin-bottom: 35px;

        .back {
            font-size: 33px;
            margin-top: -3px;
        }
    }

    @media (min-width: 1200px) {
        font-size: 26px;
        margin-bottom: 50px;

        .back {
            font-size: 36px;
            margin-top: -5px;
        }
    }
}

%btn-order-now {
    border: 0px;
    border-radius: 5px;
    padding: 8px 10px 8px 10px;
    background-color: $main-color-1;
    color: #FFFFFF;
    cursor: pointer;
    letter-spacing: 0 !important;
    
    &:focus {
        outline: none;
    }
}

%default-btn {
    // font-family: $Roboto-Bold;
    color: #FFFFFF;
    text-decoration: none;
    background-color: $main-color-1;
    font-weight: 500;
    border: 13px solid rgba(248,248,248, 0.9);
    border-radius: 1px;
    padding: 10px 12px 10px 12px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;

    &:hover {
        background-color: $main-color-2;
    }
    &:focus {
        outline: none;
    }

    @media (min-width: 1024px) {
        font-size: 15px;
        padding: 13px 14px 13px 14px;
    }
}
