@import 'assets/css/_mixins.scss';

.plate-modal {
    background-color: rgba(0,0,0,0.5);
    position: fixed;
    top: 0;
    bottom: 0;
    width: 100%;
    left: 0;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    z-index: -30;
    transition: opacity 0.3s;

    .plate-content {
        position: relative;
        background-color: #FFFFFF;
        border-radius: 8px 0px 8px 8px;
        padding: 20px;
        width: 80%;
        max-width: 500px;

        .btn-close {
            background: none;
            border: none;
            background-color: $main-color-2;
            color: #FFFFFF;
            position: absolute;
            top: 0;
            right: 0;
            margin-top: -29px;
            padding: 7px 12px;
            border-radius: 8px 8px 0px 0px;
            cursor: pointer;
        }

        .title {
            @extend %titleFont;
            font-size: 28px;
            color: $main-color-1;
            margin-bottom: 20px;
            text-align: center;
        }

        .description {
            display: flex;
            align-items: center;
            justify-content: center;
            ul {
                li {
                    margin-bottom: 10px;
                }
            }
        }
    }
}

.plate-modal.show-plate-modal {
    opacity: 1;
    z-index: 10;
}