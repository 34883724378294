@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
@import '_fonts.scss';
@import '_mixins.scss';

* {
    margin: 0;
    padding: 0
}

body, html {
    @extend %textFont-Regular;
    p { 
        color: #7e8287;
        @extend %textFont-Regular-Content;
    }
}

.btn-order-now { @extend %btn-order-now; }
.default-btn { @extend %default-btn; }
.main-color-1 { color: $main-color-1 }
.main-color-2 { color: $main-color-2 }

.lc-container {
    max-width: 900px;
    margin: auto;
    padding-top: 40px;

    @media (min-width: 1280px) {
        max-width: 1100px
    }

    // @media (min-width: 1980px) {
    //     max-width: 1500px
    // }
}

.df-padding { padding: 8px 10px 8px 10px }
.df-padding-2 { 
    padding: 90px 10px 25px 10px;
    
    @media (min-width: 760px) {
        padding: 90px 45px 25px 45px;
    }
}

.df-padding-3 { 
    padding: 50px 10px 50px 10px;
    @media (min-width: 760px) {
        padding: 35px 15px 35px 15px;
        margin: 35px auto 35px auto;
    } 
    @media (min-width: 1204px) {
        padding: 45px 45px 45px 45px;
    } 
}

.lc-default-title {
    @extend %lc-default-title;
}

.mt-15 {
    margin-top: 15px
}