@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat/Montserrat-VariableFont_wght.ttf');
}

@font-face {
  font-family: 'Arthein';
  src: url('../fonts/Arthein/Arthein.otf');
}


%common {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}

%textFont-Regular {
  @extend %common;
  font-weight: 400;
}

%textFont-Regular-Content {
  @extend %common;
  font-weight: 400;
  line-height: 20px;
  font-size: 15px;

  @media (min-width: 1024px) {
    // font-size: 17px;
    line-height: 21px;
  }
}

%textFont-Medium {
  @extend %common;
  font-weight: 500;
}

%textFont-Bold {
  @extend %common;
  font-weight: 600;
}

%textFont-ExtraBold {
  @extend %common;
  font-weight: 800;
}

%textFont-Black {
  @extend %common;
  font-weight: 900;
}

%textFont-Light {
  @extend %common;
  font-weight: 300;
}


// Freehand
// @font-face {
//   font-family: 'Freehand-Regular';
//   src: url('../fonts/Freehand/Freehand-Regular.ttf');
// }

// $Freehand-Regular: 'Freehand-Regular', sans-serif;

%titleFont {
  font-family: 'Arthein', sans-serif;
  letter-spacing: 1.5px;
}

$Freehand-Regular: 'Arthein', sans-serif;
