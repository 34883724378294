@import 'assets/css/_mixins.scss';

footer {
    background-color: #F4F5E8;
    overflow: hidden;
    
    .footer-container {
        padding-bottom: 5px !important;

        // LEFT
        .left {
            margin-bottom: 8px;
            display: flex;
            flex-direction: column;
            justify-content: center;

            .logo-container {
                text-align: center;
                margin-bottom: 15px;
    
                img { 
                    width: 60%;
                    margin: auto;
                    max-width: 300px;
                }
            }
    
            .social-networks {
                display: flex;
                align-items: center;
                justify-content: center;
    
                a {
                    img {
                        width: 35px;
                        margin-right: 15px
                    }
                    &:last-child { 
                        img { margin-right: 0px }
                    }
                }
            }

            @media (min-width: 1024px) {
                width: 25%;

                .logo-container {        
                    img { max-width: 400px;  width: 150px }
                }

                .social-networks {        
                    a {
                        img { width: 28px }
                    }
                }
            }
        }

        // RIGHT
        .right {
            .copyright { display: none }
            .development {
                text-align: center;
                margin-bottom: 25px;
                display: flex;
                flex-direction: column;

                a {
                    font-style: italic;
                    text-decoration: none;
                    color: $main-color-2;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    span {
                        font-size: 18px;
                        margin-left: 7px
                    }
                }
            }

            .address {
                position: relative;
                background-color: $main-color-1;
                padding: 20px 10px 20px 10px;
                color: #FFFFFF;
                font-weight: 600;
                p { color: #FFFFFF }
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                .item {
                    display: flex;
                    align-items: center;
                    margin-bottom: 10px;
                    &:last-child { margin-bottom: 0px }

                    span { 
                        font-size: 18px; 
                        margin-right: 7px
                    }
                }
            }

            @media (min-width: 1024px) {
                position: relative;
                width: 75%;
                display: flex;
                justify-content: flex-end;
                align-items: flex-end;
                font-size: 15px;

                .copyright {
                    display: block;
                    font-size: 14px;
                    // position: absolute;
                    left: 0;
                    bottom: 0;
                    margin-bottom: 10px;
                }

                .development {
                    margin-bottom: 0px;
                    max-width: fit-content;
                    margin-right: 25px;
                    justify-content: flex-end;
                    padding-bottom: 10px;
                    margin-left: 15px;
                }

                .address {
                    max-width: fit-content;
                    padding: 20px 15px 20px 15px;
                    align-items: flex-start;

                    &:after {
                        content: '';
                        position: absolute;
                        z-index: 1;
                        background-color: $main-color-1;
                        height: 100vh;
                        left: 0;
                        width: 100vw;
                    }

                    .item {
                        z-index: 2;
                        margin-bottom: 20px;
                        span { font-size: 17px }
                        &:last-child { margin-bottom: 0px }
                    }
                }
            }

            @media (min-width: 1280px) {
                .development {
                    // flex-direction: row;
                    align-items: flex-end;
                    font-size: 14px;

                    a {
                        span { font-size: 13px }
                    }
                }
            }
        }

        @media (min-width: 1024px) {
            display: flex;
            justify-content: space-between;
            padding: 0px !important;
            margin: 0px auto 0px auto;
        }
    }

    @media (min-width: 1024px) {
        padding: 10px 0px 10px 0px;
    }
}