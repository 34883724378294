@import 'assets/css/_mixins.scss';

.about-us-page {
  min-height: 600px;

  .about-us-container {
    // border: 1px solid blue;

    .text-container {
      // border: 1px solid red;

      .item {
        margin-bottom: 20px;
        opacity: 0;

        .title {
          margin-bottom: 5px;

          span {
            @extend %titleFont;
            color: $main-color-2;
            letter-spacing: 1px;
          }
        }

        p {
          text-align: justify;
        }

        @media (min-width: 1024px) {
          width: 48%;
          .title {
            margin-bottom: 10px;
          }

          &:nth-child(2n) {
            margin-top: 80px;
          }
        }
      }

      .item-animation {
        animation: item-animation 0.3s forwards;

        &:nth-child(1) {
          animation-delay: 0.8s;
        }
        &:nth-child(2) {
          animation-delay: 1s;
        }
        &:nth-child(3) {
          animation-delay: 1.3s;
        }
        &:nth-child(4) {
          animation-delay: 1.5s;
        }
      }

      @media (min-width: 1024px) {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
      }

      @keyframes item-animation {
        from {
          opacity: 0;
          @include transform(translateY(10px));
        }
        to {
          opacity: 1;
          @include transform(translateY(0px));
        }
      }
    }

    .text-container-additional-content {
      padding: 15px 0;
      padding: 30px;
      margin: auto;
      margin-top: 50px;
      max-width: 900px;

      h1, h2, h3, h4, h5, h6 {
        margin-bottom: 20px;
      }
      p {
        margin-bottom: 10px;
      }

      @media (min-width: 900px) {

      }
    }
  }
}
