@import 'assets/css/_mixins.scss';

.home {
  overflow-x: hidden;

  .explore-menu {
    position: relative;
    background-color: #f4f5e8;
    background-image: url('../../assets/img/point.svg');
    background-size: 5px 5px;

    .plate {
      &:not(:nth-child(-n+7)) {
        display: none;
      }
    }

    &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      right: -100%;
      background-color: #f4f5e8;
      background-image: url('../../assets/img/point.svg');
      background-size: 5px 5px;
    }

    .container-menu-button {
      margin-top: 45px;
      margin-bottom: 20px;
      text-align: center;

      .btn-menu {
        border: 13px solid rgba(249, 232, 217, 1);
      }
    }
  }

  .customer-reviews {
    .title {
      margin-top: 20px;
      .back {
        margin-top: -20px;
      }

      @media (min-width: 760px) {
        margin-top: 0px;
        .back {
          margin-top: -5px;
        }
      }
    }
  }

  .open-gallery {
    // border: 2px solid green;

    .container-imgs {
      .images {
        // border: 1px solid red;
        .image {
          width: 90%;
        }
        .tablet,
        .desktop,
        .desktop-lg {
          display: none;
        }

        @media (min-width: 760px) {
          display: flex;

          .image {
            width: 30%;
          }
          .tablet {
            display: block;
          }
        }

        @media (min-width: 1024px) {
          width: 80%;
          .image {
            width: 33%;
          }
        }
        @media (min-width: 1280px) {
          .desktop {
            display: block;
          }
          .image {
            width: 20%;
          }
        }
      }

      .text {
        padding: 0px 10px 0px 10px;
        margin-top: 15px;

        p {
          @extend %titleFont;
          font-size: 22px;
          color: $main-color-1;
          font-weight: 600;
          line-height: 29px;

          span {
            // font-family: $textFont-Black;
            @extend %textFont-Black;
            letter-spacing: 0px;
            color: $main-color-1;
          }
        }
      }

      @media (min-width: 760px) {
        .text {
          display: none;
        }
      }

      @media (min-width: 1024px) {
        display: flex;
        align-items: center;
        .text {
          margin-top: 0px;
          width: 20%;
          display: block;
          padding-right: 20px;
          overflow-wrap: break-word;

          p {
            font-size: 20px;
          }
        }
      }

      @media (min-width: 1280px) {
        .text {
          padding: 0px 10px 0px 18px;
          p {
            font-size: 25px;
          }
        }
      }

      @media (min-width: 1920px) {
        .text {
          p {
            font-size: 30px;
          }
        }
      }
    }

    .container-button {
      text-align: right;
      margin-right: 15px;
      margin-top: 40px;

      @media (min-width: 1280px) {
        margin-top: 45px;
        margin-right: 25px;
      }
    }
  }
}
