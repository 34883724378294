@import 'assets/css/_mixins.scss';

.gallery-container {
    min-height: 900px;
  .gallery {
    // border: 1px solid green;
    .gallery-title {
      .hashtag {
        @extend %titleFont;
        letter-spacing: 1px;
        color: $main-color-1;
      }
    }
    .title {
      margin-bottom: 15px;
    }

    .items {
      .item {
        position: relative;
        height: 250px;
        border-radius: 15px;
        @include transition(box-shadow 0.3s);
        margin-bottom: 25px;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
        cursor: pointer;
        overflow: hidden;

        .item-bg {
          width: 100%;
          height: 100%;
          background-size: cover;
          background-position: center center;
          display: flex;
          align-items: center;
          justify-content: center;
          @include transition(transform 0.3s);

          // Caption
          .caption {
            color: #ffffff;
            display: flex;
            flex-direction: column;
            justify-content: center;
            z-index: 2;
            text-align: center;
            max-width: 95%;
            opacity: 0;

            .caption-title {
              @extend %textFont-Bold;
              font-size: 22px;
              font-style: italic;
              margin-bottom: 8px;
            }

            p {
              @extend %titleFont;
              font-size: 18px;
              color: #ffffff;
            }
          }

          .text {
            border: 2px solid black;
            display: none;
          }

          .btn-see-more {
            position: absolute;
            bottom: 0;
            right: 0;
            width: 40%;
            color: #ffffff;
            font-size: 15px;
            padding: 10px 5px 10px 5px;
            border-radius: 15px 0px 15px 0px;
            border: 0px;
            background-color: $main-color-1;
            opacity: 0;
            animation: btn-see-more 1s ease forwards;
            animation-delay: 0.5s;
          }
        }

        @media (min-width: 1024px) {
          &:hover {
            .item-bg {
              @include transform(scale(1.085));

              &:before {
                content: '';
                position: absolute;
                top: 0;
                width: 100%;
                height: 100%;
                background-color: rgba(0, 0, 0, 0.5);
              }

              .caption {
                opacity: 1;
              }
            }
          }
        }
      }

      @media (min-width: 760px) {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .item {
          width: 30%;
          display: flex;
          align-items: center;
          margin-bottom: 30px;
        }
      }

      @media (min-width: 1024px) {
        .btn-see-more {
          display: none;
        }
      }
    }
  }

  @keyframes btn-see-more {
    from {
      margin-bottom: -35px;
    }
    to {
      opacity: 1;
      margin-bottom: 0px;
    }
  }

  .container-image-full {
    background-color: rgba($color: #000000, $alpha: 0.5);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    z-index: -15;
    @include transition(all 0.3s);

    .btn-close {
      position: absolute;
      top: 0;
      right: 0;
      margin: 8px;
      cursor: pointer;
      font-size: 40px;
      color: #ffffff;
    }

    .image {
      width: 80%;
      max-width: 600px;
      height: 30%;
      min-height: 200px;
      position: relative;
      border-radius: 5px;
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
      display: flex;
      align-items: flex-end;
      box-shadow: inset 0px -103px 39px -35px rgba(0, 0, 0, 0.75);
      @include transition(background-image 0.2s);

      .text {
        color: #ffffff;
        margin: 15px;

        .title {
          font-size: 18px;
          @extend %titleFont;
          letter-spacing: 1px;
        }

        .description {
          color: #ffffff;
          margin-top: 5px;
          display: none;
        }

        @media (min-width: 760px) {
          .title {
            font-size: 25px;
          }
          .description {
            display: block;
          }
        }
      }

      .arrow {
        font-size: 28px;
        position: absolute;
        top: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        color: #ffffff;
        cursor: pointer;
        @include transition(opacity 0.3s);

        &:hover {
          opacity: 0.8;
        }
      }
      .arrow-left {
        left: 0;
        margin-left: -27px;
      }
      .arrow-right {
        right: 0;
        margin-right: -33px;
      }

      // Items thimbnail
      .items-thumbnail {
        position: absolute;
        bottom: 0;
        margin-bottom: -45px;
        width: 100%;
        height: 35px;
        overflow: hidden;
        padding: 3px 0px 3px 0px;
        white-space: nowrap;

        .thumbnail {
          display: inline-block;
          border: 1px solid #ffffff;
          width: 40px;
          height: 35px;
          margin: 0px 15px 15px 15px;
          cursor: pointer;
          background-size: cover;
          background-position: center center;
          @include transition(opacity 0.3s);

          &:hover {
            opacity: 0.8;
          }
        }
      }

      @media (min-width: 760px) {
        height: 400px;
      }

      @media (min-width: 1280px) {
        max-width: 680px;
        height: 480px;

        .arrow {
          font-size: 38px;
        }
        .arrow-left {
          margin-left: -34px;
        }
        .arrow-right {
          margin-right: -44px;
        }
      }
    }
  }

  .show-container-image-full {
    opacity: 1;
    z-index: 12;
  }
}
