@import 'assets/css/_mixins.scss';

#hot_deals {
  position: relative;

  .arrow-next {
    font-size: 55px;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    margin-right: -10px;
    display: flex;
    align-items: center;
    cursor: pointer;
    color: $main-color-3;
    z-index: 2;

    &:active {
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }

    @media (min-width: 1280px) {
      font-size: 50px;
      margin-right: 80px;
    }
  }

  .hot-dealts-container {
    position: relative;

    // Left
    .left {
      .container-image {
        text-align: center;
        margin-bottom: 40px;

        .image {
          width: 200px;
          border-radius: 5%;
          box-shadow: -5px 10px 13px -3px rgba(46, 46, 46, 0.6);
          -webkit-box-shadow: -5px 10px 13px -3px rgba(46, 46, 46, 0.6);
          -moz-box-shadow: -5px 10px 13px -3px rgba(46, 46, 46, 0.6);
        }
      }

      @media (min-width: 760px) {
        width: 50%;
        margin-right: 30px;

        .container-image {
          margin-bottom: 0px;
          .image {
            width: 300px;
          }
        }
      }

      @media (min-width: 1024px) {
        margin-right: 0px;
      }

      @media (min-width: 1180px) {
        .container-image {
          .image {
            width: 380px;
          }
        }
      }

      @media (min-width: 1280px) {
        .container-image {
          .image {
            width: 400px;
          }
        }
      }
    }

    // Right
    .right {
      .data {
        .first-title {
          @extend %titleFont;
          color: $main-color-2;
          font-size: 28px;
          margin-bottom: -3px;
        }
        .second-title {
          // color: $main-color-1;
          margin-bottom: 10px;
        }
        .container-button {
          margin-top: 45px;
        }
        // .description {
        //   font-size: 15px;
        // }
      }

      @media (min-width: 760px) {
        width: 50%;
        padding-right: 50px;
      }

      @media (min-width: 1024px) {
        .data {
          .second-title {
            margin-bottom: 15px;
          }
          .description {
            max-width: 380px;
          }
          .container-button {
            margin-top: 55px;
          }
        }
      }

      @media (min-width: 1180px) {
        .data {
          .first-title {
            font-size: 38px;
          }
          .second-title {
            font-size: 32px;
            margin-bottom: 20px;
          }
          .description {
            font-size: 15px;
          }
          .container-button {
            margin-top: 60px;
          }
        }
      }
    }

    @media (min-width: 760px) {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  // Hot deals Animations
  .hot-deal-enter {
    @include transform(translateX(120px));
    opacity: 0;
  }
  .hot-deal-enter-active {
    @include transform(translateX(0px));
    @include transition(all 0.7s);
    opacity: 1;
  }

  .hot-deal-exit {
    @include transform(translateX(0px));
    @include transition(all 0.7s);
    position: absolute;
    top: 0;
    opacity: 0;
  }
  .hot-deal-exit-active {
    @include transform(translateX(-320px));
    opacity: 0;
  }
}
