
.main-greetings{
  position: relative;
  width: 100%;
  height: 800px;
  background-color: #fac458 !important; 

  .snow_wrap{
    height: 100%;
    width: 100%;
    background-size: cover;
    position: absolute;
    overflow: hidden;
  }

  .snow, .snow::after, .snow::before{
    content: "";
    position: absolute;
    inset: 0 0 0 0;
    top: -650px;
    background-image: 
    radial-gradient(4px 4px at 100px 50px, #fff, transparent),
    radial-gradient(6px 6px at 200px 150px, #fff, transparent),
    radial-gradient(3px 3px at 300px 250px, #fff, transparent),
    radial-gradient(4px 4px at 400px 350px, #fff, transparent),
    radial-gradient(6px 6px at 500px 100px, #fff, transparent),
    radial-gradient(3px 3px at 50px 200px, #fff, transparent),
    radial-gradient(4px 4px at 150px 300px, #fff, transparent),
    radial-gradient(6px 6px at 250px 400px, #fff, transparent),
    radial-gradient(3px 3px at 350px 500px, #fff, transparent);
    background-size: 650px 650px;


    animation: snowAnim 3s linear;
    animation-iteration-count: infinite;
  }

  .snow::after{
    margin-left: -250px;
    opacity: 0.5;
    filter:blur(2px);
    animation-direction: reverse;
    animation-duration: 6s;
  }

  .snow::before{
    margin-left: -350px;
    opacity: 0.7;
    filter: blur(1px);
    animation-direction: reverse;
    animation-duration: 6s;
  }

  @keyframes snowAnim {

    from{
      transform: translateY(0);
    }
    to{
     transform: translateY(650px); 
    }
    
  }



  .greetings-content{
    position: absolute;
    width: 100%;
    // background-color: rgba(0, 119, 255, 0.548);
    padding: 9em 0em 5em 0em;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 500px;

    .leftside{
      
      // background-color: greenyellow;
      justify-self: flex-start;
      .dish-name{
        font-size: 2.5em;
        font-weight: 700;

      }
      width: 400px;
      .hot-title{
        font-size: 3em;
        font-weight: 700;
        color: #830000;

      }
      .description{

      }

      .order-button{
        margin: 1em 0;
        background-color: #830000;
        color: white;
        font-size: 1.2em;
        font-weight: 500;
        width: fit-content;
        padding: 10px 1em;
        border: 1px solid #830000;
        cursor: pointer;
      }

      .order-button:hover{
        background-color: #ffc248;
        color: black;
        border: 1px solid black;
        transition: all 0.2s ease-in;
      }

      .socialmedia-container{
        display: flex;
        gap: 10px;
        
        img{
          cursor: pointer;
          height: 30px;
          width: 30px;
        }
      }


    }

    .rightside{
      height: 100%;
      width: 600px;
      // background-color: rgba(255, 0, 0, 0.055);
      display: flex;
      justify-content: right;
      .christmas-ball-container{
        position: absolute;
        
        .christmas-ball {
          position: relative;
          width: 450px; /* Diameter of the ball */
          height: 450px; /* Diameter of the ball */
          border-radius: 50%; /* Makes it a perfect circle */
          background: radial-gradient(circle, #ad1421 60%, #971010 100%);
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3), inset 0 -3px 6px rgba(0, 0, 0, 0.2);
          display: flex;
          justify-content: center;
          align-items: center;
          overflow: hidden; /* Ensures images stay inside the circle */
          border: 2px solid #fff; /* Adds a border around the ball */
        }
        
        .christmas-ball img {
          width: 100%;
          height: 100%;
          object-fit: cover; /* Ensures the image fits within the circle */
          border-radius: 50%; /* Keeps the image circular inside the ball */
        }
        
        /* Top Holder */
        .top-holder {
          position: absolute;
          top: -20px; /* Place it above the ball */
          left: 50%;
          transform: translateX(-50%);
          width: 40px;
          height: 20px;
          background: #c0c0c0; /* Metallic color */
          border-radius: 10px 10px 0 0; /* Rounded top edges */
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3); /* Add depth to the holder */
          display: flex;
          justify-content: center;
          align-items: center;
        }
        
        /* Loop inside the Holder */
        .top-holder::before {
          content: '';
          position: absolute;
          top: -10px; /* Place the loop above the holder */
          width: 15px;
          height: 10px;
          border: 2px solid #c0c0c0; /* Metallic color for the loop */
          border-radius: 50%; /* Makes it a rounded loop */
          background: transparent; /* Empty inside the loop */
        }
        
        /* String */
        .string {
          position: absolute;
          top: -140px; /* Length of the string */
          left: 50%;
          transform: translateX(-50%);
          width: 2px;
          height: 140px; /* Length of the hanging string */
          background: #c0c0c0; /* String color */
          z-index: -1; /* Place it behind the holder and ball */
        }
        
      }

      .slider-btns{
        div{
          padding: 5px 10px;
        }
        display: flex;
        height: fit-content;
        align-self: flex-end;
        .previous{

        }
        .next{
          background-color: #830000;
          color: white;
        }
      }
    }
  }

  .background-container {
    position: relative;
    height: 100vh;
    width: 100%;
  }
  
  .stars {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(white 1px, transparent 1px),
                radial-gradient(white 1px, transparent 1px);
    background-size: 2px 2px, 3px 3px;
    background-position: 0 0, 50px 50px;
    opacity: 0.8;
  }
  
  .landscape {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 40%;
    background: white;
    clip-path: polygon(0% 100%, 0% 30%, 10% 50%, 25% 40%, 50% 60%, 70% 50%, 90% 70%, 100% 30%, 100% 100%);
  }
  
  .scene {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 40%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: flex-end;
  }
  
  .tree {
    width: 60px;
    height: 80px;
    background: none;
    position: absolute;
  }
  
  .tree svg {
    width: 100%;
    height: 100%;
  }
  
  .gift {
    position: absolute;
    width: 50px;
    height: 50px;
    background: #f44336;
    border-radius: 10px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
  }
  
  .gift:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 10px;
    background: #ffeb3b;
    transform: translateY(-50%);
  }
  
  .gift:after {
    content: '';
    position: absolute;
    left: 50%;
    top: 0;
    width: 10px;
    height: 100%;
    background: #ffeb3b;
    transform: translateX(-50%);
  }
  
  
  .tree-1 {
    left: 10%;
    bottom: 20%;
  }
  
  .tree-2 {
    left: 50%;
    bottom: 10%;
  }
  
  .tree-3 {
    left: 30%;
    bottom: 20%;
  }
  
  .tree-4 {
    left: 75%;
    bottom: 10%;
  }
  
  .tree-5 {
    left: 60%;
    bottom: 15%;
  }

  .tree-6{
    left: 92%;
    bottom: 7%;
  }
  
  .gift-1 {
    left: 18%;
    bottom: 20%;
  }
  
  .gift-2 {
    left: 700%;
    bottom: 18%;
  }
  
  .gift-3 {
    left: 31%;
    bottom: 8%;
    
  }
  
  .gift-4 {
    left: 100%;
    bottom: 50%;
  }
  
  .gift-5 {
    left: 65%;
    bottom: 25%;
  }

  .background-container {
    position: absolute;
    height: 100%;
    width: 100%;
  }



  .landscape {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 20%;
    background: rgb(180, 38, 38);
    clip-path: polygon(0% 100%, 0% 30%, 10% 50%, 25% 40%, 50% 60%, 70% 50%, 90% 70%, 100% 30%, 100% 100%);
  }

  .scene {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 40%;
    display: flex;
    justify-content: space-around;
    align-items: flex-end;
  }

  .tree {
    width: 60px;
    height: 80px;
    background: none;
  }

  .tree svg {
    width: 100%;
    height: 100%;
  }

  .gift {
    position: relative;
    width: 50px;
    height: 50px;
    background: #d62828;
    border-radius: 4px;
  }

  .gift:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 10px;
    background: #fcbf49;
    transform: translateY(-50%);
  }

  .gift:after {
    content: '';
    position: absolute;
    left: 50%;
    top: 0;
    width: 10px;
    height: 100%;
    background: #fcbf49;
    transform: translateX(-50%);
  }

}




