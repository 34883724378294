:root {
  
}

.carroza-two-container {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: min-content;
  background: repeat url('../../assets/img/carrousel/bg.webp');
  background-size: contain;


  .carroza-two {
    // background-color: rgb(225, 225, 225);
    width: 1100px;
    // height: 650px;

    @media (max-width: 1105px) {
      width: 1000px;

    }

    @media (max-width: 1020px) {
      width: 900px;

    }

    @media (max-width: 910px) {
      width: 800px;

    }

    @media (max-width: 824px) {
      width: 700px;

    }
    @media (max-width: 705px) {
      width: 600px;

    }

    @media (max-width: 644px) {
      width: 550px;
    }

    @media (max-width: 563px) {
      width: 420px;
    }


    // -webkit-box-shadow: 0px 0px 108px #00000012;

    // -moz-box-shadow: 0px 0px 108px #00000012;
  
    // box-shadow: 0px 0px 108px #00000012;

    .carroza-gallery::before{
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 30px;
        height: 100%;
        background: linear-gradient(to left, #F9F5EA00, #f9f5ea5c, #F9F5EA);
        z-index: 10;
    }

    .carroza-gallery::after{
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        width: 30px;
        height: 100%;
        background: linear-gradient(to right, #F9F5EA00, #f9f5ea5c, #F9F5EA);
        z-index: 10;
    }

    .carroza-gallery {
      // background-color: aqua;
      // padding: 2em 0;
      // padding-left: 7em;
      height: fit-content;
      
      position: relative;


      .horizontalSlider___281Ls, .carousel__slider carousel__slider--horizontal{
        @media (max-width: 563px) {
          height: 400px;
        }

        @media (max-width: 305px) {
          height: 320px;
        }
      }

      .platito-container:hover img{
        // filter: grayscale(0);
      }

      @media (max-width: 563px) {
        height: 370px;
        
      }
      @media (max-width: 305px) {
        height: 340px;
      }

      .platito-container {
        // background-color: rgba(32, 39, 108, 0.398);
        margin-left: 15px;
        position: relative;
        width: var(--dw);
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        overflow-x: hidden;

        @media (max-width: 563px) {
          margin-left: 16%;
          width: fit-content;
          
        }

        @media (max-width: 305px) {
          margin-left: 22%;
        }

        @media (max-width: 305px) {
          height: 340px;
          width: 230px;
        }

        .the-pop-up {
          position: absolute;
          top: 0;
          left: 0;
          background-color: #1d4926;
          display: flex;
          width: 100%;
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
          padding: 15px 0 10px 0;
          opacity: 90%;
          z-index: 5;
          opacity: 0%;
          transition: all 0.2s ease-in;
          display: block;

          @media (max-width: 563px) {
            
            
          }

          a {
            width: fit-content;
            color: white;
            display: block;
            border-bottom: 1px solid #1d4926;
            text-align: center;
            text-decoration: none;
            text-decoration: none;
            margin: 0 auto;
          }
          :hover {
            border-bottom: 1px solid white;
            transition: all 0.1s ease;
          }

          .refs {
            @media (max-width: 650px) {
              font-size: 0.7em;
            }

            @media (max-width: 650px) {
              font-size: 0.7em;
            }

            @media (max-width: 910px) {
              font-size: 0.8em;
        
            }
          }
        }:hover{
            img {
                // filter: grayscale(1);
                transition: all 0.2s ease-in;
              }
        }

        img {
          height: 400px;
          width: 100%;
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;

          @media (max-width: 1105px) {
            height: 350px;
      
          }
          @media (max-width: 1020px) {
            height: 320px;
      
          }

          @media (max-width: 910px) {
            height: 280px;
      
          }

          @media (max-width: 824px) {
            height: 380px;
      
          }
          @media (max-width: 705px) {
            height: 330px;
      
          }
          @media (max-width: 644px) {
            height: 290px;
          }
          @media (max-width: 563px) {
            height: 330px;
            width: 280px;
            
          }
          @media (max-width: 305px) {
            height: 270px;
            width: 230px;
          }


        }
        :hover {
          // filter: grayscale(0);
        }

        .platito-name {
          font-weight: 500;
          font-size: 1.1em;
          @media (max-width: 650px) {
            font-size: 0.9em;
          }
        }
      }
      :hover {
        .the-pop-up {
          opacity: 90%;
          transition: all 0.15s ease-in;
        }

        img {
          // filter: grayscale(1);
          transition: all 0.2s ease-in;
        }
      }
    }
    :hover {
      .the-pop-up {
        opacity: 0%;
        transition: all 0.2s ease-in;
      }
    }

    .slider-buttons {
      display: flex;
      justify-content: center; 
      align-items: center;
      margin: 1em 0 2em 0;
      gap: 10px;
      
      z-index: 10;

      .btn-back,
      .btn-next {
        background-color: whitesmoke;
        border: none;
        border-radius: 5px;
        height: 40px;
        width: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.5em;
        transition: all ease 0.1s;
      }

      .btn-next {
        background-color: #2c703a;
        color: white;
        -webkit-box-shadow: 0px 0px 2px #000000;
        -moz-box-shadow: 0px 0px 2px #000000;
        box-shadow: 0px 0px 2px #000000;
      }

      .btn-next:hover {
        background-color: #3ea751;
        transition: all ease 0.1s;
      }

      .btn-back {
        -webkit-box-shadow: 0px 0px 2px #000000;
        -moz-box-shadow: 0px 0px 2px #000000;
        box-shadow: 0px 0px 2px #000000;
      }

      .btn-back:hover {
        background-color: #ffffffa5;
        transition: all ease 0.1s;
      }
    }

    .carroza-title {
      width: fit-content;
      margin: 1em auto;
    }

    .carroza-title {
      font-size: 10px;
      font-weight: bold;
      color: #fff;
      font-size: 1.4em;
      display: flex;
    }

    .carroza-title {
      --r: 0.8em;
      /* control the ribbon shape */

      padding-inline: calc(var(--r) + 0.3em);
      line-height: 1.8;
      clip-path: polygon(0 0, 100% 0, calc(100% - var(--r)) 50%, 100% 100%, 0 100%, var(--r) 50%);
      background: #5b9b69;
      /* the main color */
      width: fit-content;
      padding: 0.5em 100px;

      @media (max-width: 1136px) {
        text-align: center;
        width: 700px;
      }
      @media (max-width: 1060px) {
        text-align: center;
        width: 600px;
      }
      @media (max-width: 824px) {
        text-align: center;
        width: 400px;
        font-size: 1.3em;
      }
      @media (max-width: 644px) {
        text-align: center;
        width: 300px;
      }
      @media (max-width: 563px) {
        font-size: 1em;
        text-align: center;
        width: 300px;
        padding: 0.5em 4em;
      }
      @media (max-width: 470px) {
        text-align: center;
        width: 300px;
        padding: 0.5em 2em;
      }

      @media (max-width: 392px) {
        text-align: center;
        width: 250px;
        padding: 0.5em 2em;
      }
      @media (max-width: 342px) {
        font-size: 1em;
        text-align: center;
        width: 220px;
        padding: 0.5em 2em;
      }
    }
  }

  .top-end-content {
    height: 30px;
    width: 100%;
    // background-color: greenyellow;
    border-bottom: 5px dotted #5b9b69;
  }
  .bottom-end-content {
    height: 30px;
    width: 100%;
    // background-color: greenyellow;
    border-top: 5px dotted #5b9b69;
  }
}
