#customer_reviews {
  margin-top: 45px;

  .review {
    margin-bottom: 25px;
    display: flex;
    align-items: flex-start;

    .photo {
      width: 80px;
      margin-right: 10px;
      margin-bottom: 5px;
      img {
        width: 100%;
      }
    }
    .text {
      width: 85%;
      background-color: #f4f5e8;
      padding: 15px 15px 15px 15px;
      border-radius: 0px 20px 20px 20px;
      margin-top: 15px;
    }
    .quote {
      text-align: right;
      margin-top: 5px;
      font-style: italic;
      font-size: 13px;
    }
  }

  @media (min-width: 760px) {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    .review {
      width: 32%;
    }

    p {
        font-size: 14px;
      }
  }
}
